<template>
  <div class="col-12 col-lg-7 c">
    <div class="card">
      <div class="card-header">
        <h4 class="text-center">
          <i class="fa fa-user-plus"></i>
          إضافة مشرف جديد
        </h4>
      </div>
      <div class="card-body">
        <div class="col-12 text-center">
          <img
            :src="
              image ? image : require('@/assets/images/avatars/avatar2.png')
            "
            style="
              width: 100px;
              height: 100px;
              cursor: pointer;
              object-fit: cover;
              border-radius: 50%;
            "
          />
          <div id="progress-wrp">
            <div class="progress-bar"></div>
            <div class="status">0%</div>
          </div>
          <br />
          <input type="file" title="اضغط لتغيير الصورة" id="ingredient_file" />
        </div>
        <div class="form-group">
          <h5 for="">الإسم</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="name"
          />
        </div>
        <div class="form-group">
          <h5 for="">إسم المستخدم</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="username"
          />
        </div>
        <div class="form-group">
          <h5 for="">الهاتف</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="phone"
          />
        </div>
        <div class="form-group">
          <h5 for="">كلمة المرور</h5>
          <input
            type="text"
            class="form-control"
            name=""
            id=""
            aria-describedby="helpId"
            placeholder=""
            v-model="password"
          />
        </div>
        <div class="form-group">
          <h5 for="">القسم</h5>
          <select class="form-control" name="" v-model="section_id" id="">
            <template v-for="section in sections">
              <option :value="section._id" :key="section._id">
                {{ section.title }}
              </option>
            </template>
          </select>
        </div>
        <div class="form-group">
          <h5 for="">ملاحظات</h5>
          <textarea
            class="form-control"
            name=""
            v-model="notes"
            id=""
            rows="3"
          ></textarea>
        </div>
        <div class="col-12 g">
          <div class="card card-body border">
            <div class="form-check">
              <h5 class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="all_rules"
                  :value="true"
                />
                مسموح له بكل الباصات وخطوط السير
              </h5>
            </div>
            <div v-if="!all_rules">
              <br />
              <strong class="g">الباصات المسموحة:</strong>
              <br />
              <br />
              <ul>
                <li v-for="bus in busses" :key="bus._id">
                  <div class="form-check">
                    <h5 class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name=""
                        id=""
                        v-model="allowed_busses"
                        :value="bus._id"
                      />
                      {{ bus.title }}
                    </h5>
                  </div>
                </li>
              </ul>
              <br />
              <strong class="g">خطوط السير المسموحة:</strong>
              <br />
              <br />
              <ul>
                <li v-for="group in groups" :key="group._id">
                  <div class="form-check">
                    <h5 class="form-check-label">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name=""
                        id=""
                        v-model="allowed_groups"
                        :value="group._id"
                      />
                      {{ group.title }}
                    </h5>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" @click="add()">
            إضافة المشرف
            <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    checkPer(this, "admins_bus", "add");
    var g = this;
    $.post(api + "/admin/groups/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.groups = JSON.parse(r).response;
    });
    $.post(api + "/admin/bus/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.busses = JSON.parse(r).response;
    });
    var Upload = function (file) {
      this.file = file;
    };

    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("upload_file", true);
      $.ajax({
        type: "POST",
        url: api + "/admin/students/upload-avatar",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          $("#progress-wrp").fadeOut("slow");
          g.image = data.response;
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };
    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
    $.post(api + "/admin/sections/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.sections = JSON.parse(r).response;
    });
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      name: null,
      password: 123456,
      username: null,
      all_rules: false,
      phone: null,
      image: null,
      notes: null,
      rules: [],
      busses: [],
      groups: [],
      allowed_busses: [],
      allowed_groups: [],
      section_id: null,
      sections: [],
    };
  },
  methods: {
    add() {
      var g = this;
      if (!g.username || !g.password || !g.name) {
        alert("هناك حقول مطلوبة");
      } else {
        $.post(api + "/admin/admins/bus/add", {
          jwt: g.user.jwt,
          name: g.name,
          username: g.username,
          password: g.password,
          all_rules: g.all_rules,
          section_id: g.section_id,
          rules: {
            groups: g.allowed_groups,
            busses: g.allowed_busses,
          },
          image: g.image,
          notes: g.notes,
          phone: g.phone,
        })
          .then(function (r) {
            r = JSON.parse(r);
            if (r.status == 100) {
              alert("تم اضافة المشرف بنجاح");
              g.$router.push("/bus/admins");
            } else {
              alert(r.response);
            }
          })
          .catch(function (r) {
            alert("حدث خطأ");
          });
      }
    },
  },
};
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>